import { transform } from "lodash";
import { camelize } from "./OperatorListingJson";

export const formatValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? "True" : "False";
    }
    if (value === undefined) {
        return "False"
    }
    return value;
};
export const createConfigArray = (finalValue, response) => {
    const config = {};

    const taskEnabledValue = finalValue["TaskEnabled"];
    const brmTaskEnabledValue = finalValue["BRMTaskEnabled"];

    const paymentProviderValue = finalValue["PaymentProvider"];
    const identityProviderValue = finalValue["IdentityProvider"]

    const customerSearchValue = finalValue["CustomerSearch"]
    const customerListingValue = finalValue["CustomerListing"]

    const limit = finalValue["Limit"]

    const ExpiryInSeconds = finalValue["ExpiryInSeconds"]

    const mode = finalValue["Mode"]

    Object.entries(finalValue).forEach(([key, value]) => {
        let managementName = '';
        const managementNameMapping = {
            "Payment": "PaymentManagement",
            "identity": "identityManagement",
            "whatsApp": "whatsAppManagement",
            "notification": "NotificationManagement",
            "Task": "TaskManagement",
            "policy": "policySpecifications",
            "Customer": "CustomerRetrieval",
            "self": "selfcare",
            "order": "orderManagement",
            "Billing":"BillingManagement",
            "LLM":"LLM",
            "Session":"SessionAudit",
            "Usage":"UsageEventsPersistanceMode",
            "usage":"UsageEventsPersistanceMode",
            "reservation":"ReservationUnitsExpiration",
            "sessionAuditBuffer":"SessionAuditBuffer",
            "rated":"Rated Event Consumer Configuration"
        };
        function getManagementName(key) {
            let managementName = null;

            Object.entries(managementNameMapping).forEach(([prefix, name]) => {
                if (prefix === "Task" && key.includes(prefix)) {
                    managementName = name
                }
                if (prefix === "Customer" && key.includes(prefix)) {
                    managementName = name
                }
                else if (key.startsWith(prefix)) {
                    managementName = name;
                }
            });

            return managementName;
        }

        managementName = getManagementName(key)
        if (managementName) {
            if (!config[managementName]) {
                config[managementName] = {
                    name: managementName,
                    value: [],
                    operatorId: response?.data?.adminDetails?.operatorId,
                    type: response?.data?.domain,
                    level: "Operator"
                };
            }

            const managementConfig = {
                "TaskManagement": [
                    { key: "TaskEnabled", value: formatValue(taskEnabledValue) },
                    { key: "BRMTaskEnabled", value: formatValue(brmTaskEnabledValue) }
                ],
                "PaymentManagement": [
                    { key: "PaymentProvider", value: paymentProviderValue },
                    { key: "PaymentStatus", value: value ? "ACTIVE" : "INACTIVE" }
                ],
                "identityManagement": [
                    { key: "IdentityProvider", value: identityProviderValue },
                    { key: "Enable", value: value ? "True" : "False" }
                ],
                "orderManagement": [
                    { key: "OrderEnabled", value: value ? "True" : "False" }
                ],
                "NotificationManagement": [
                    { key: "NotificationEnable", value: finalValue["NotificationEnable"] ? "True" : "False" },
                    { key: "SetPassword", value: finalValue["SetPassword"] ? "True" : "False" },
                    { key: "ProfileUpdate", value: finalValue["ProfileUpdate"] ? "True" : "False" },
                    { key: "EmailUpdate", value: finalValue["EmailUpdate"] ? "True" : "False" },
                    { key: "BalanceNotification", value: finalValue["BalanceNotification"] ? "True" : "False" },
                    { key: "BalanceAVNotification", value: finalValue["BalanceAVNotification"] ? "True" : "False" },
                    { key: "CreateCustomer", value: finalValue["CreateCustomer"] ? "True" : "False" },
                    { key: "CustomerUpdate", value: finalValue["CustomerUpdate"] ? "True" : "False" },
                    { key: "AddOnPurchase", value: finalValue["AddOnPurchase"] ? "True" : "False" },
                    { key: "PurchaseOffer", value: finalValue["PurchaseOffer"] ? "True" : "False" },
                    { key: "UnsubscribeEmail", value: finalValue["UnsubscribeEmail"] ? "True" : "False" },
                    { key: "ChangePlan", value: finalValue["ChangePlan"] ? "True" : "False" },
                    { key: "PaymentOrder", value: finalValue["PaymentOrder"] ? "True" : "False" },
                    { key: "PaymentConfirmation", value: finalValue["PaymentConfirmation"] ? "True" : "False" },
                    { key: "CancelProductOrder", value: finalValue["CancelProductOrder"] ? "True" : "False" },
                    { key: "SubscriptionFailed", value: finalValue["SubscriptionFailed"] ? "True" : "False" },
                    { key: "SubscriptionConfirmed", value: finalValue["SubscriptionConfirmed"] ? "True" : "False" },
                    { key: "CommissionStatement", value: finalValue["CommissionStatement"] ? "True" : "False" }
                ],
                "CustomerRetrieval": [
                    { key: "CustomerSearch", value: formatValue(customerSearchValue) },
                    { key: "CustomerListing", value: formatValue(customerListingValue) }
                ],
                "BillingManagement":[
                    { key :"BillingEnabled", value: value ? "True": "False"}
                ],
                "SessionAuditBuffer":[
                    {key :"Limit", value: limit}
                ],
                "LLM":[
                    { key :"LLMEnabled", value: value ? "True": "False"}
                ],
                "SessionAudit":[
                    {key :"Enabled", value: value ? "True" : "False"}
                ],
                "UsageEventsPersistanceMode": [
                    { key: "Mode", value: mode },
                ],
                "ReservationUnitsExpiration":[
                    {key :"ExpiryInSeconds", value: ExpiryInSeconds}
                ],
                "Rated Event Consumer Configuration":[
                    {key :"Trigger One Bill API", value: finalValue["Trigger One Bill API"] ? "true" : "false" },
                    {key :"Generate CSV File", value: finalValue["Generate CSV File"] ? "true" : "false"}
                ]
            };
            if (managementConfig[managementName]) {
                managementConfig[managementName].forEach(item => {
                    if (!config[managementName].value.some(existingItem => existingItem.key === item.key)) {
                        config[managementName].value.push({
                            key: item.key,
                            value: [item.value]
                        });
                    }
                });
            } else {
                const formattedValue = formatValue(value);
                if (!config[managementName].value.some(item => item.key === key)) {
                    config[managementName].value.push({
                        key: key,
                        value: [formattedValue]
                    });
                }
            }

        }
    });
    return Object.values(config);
};

export const combinedConfigurations = (OperatorDefaultConfigurationList) => {
    const configList = [...OperatorDefaultConfigurationList];

    const paymentIndex = configList.findIndex(config => config.name === "paymentManagement");
    const identityIndex = configList.findIndex(config => config.name === "identityManagement");

    if (paymentIndex !== -1 && identityIndex !== -1) {
        const identityConfig = configList.splice(identityIndex, 1)[0];
        configList.splice(paymentIndex + 1, 0, identityConfig);
    }

    return configList;
};

export const mapConfig = (isEdit, configData, OperatorDefaultConfigurationList, setSwitchState, setSelectState) => {
    const initialSwitchState = {};
    const initialSelectState = {};

    const config = isEdit ? configData?.configurations : OperatorDefaultConfigurationList
    config?.forEach(config => {
        const camelizedName = camelize(config.name);
        if (!isEdit) {
            if(config.name==="paymentManagement"||config.name==="identityManagement"){
                initialSelectState[config.value[0].key]={ label: "", value: "" }
            }else{
                initialSwitchState[camelizedName] = false;
            }
        }
        else {
            config.value.forEach((val) => {
                const switchStateMapping = {
                    "PaymentStatus": { target: "paymentManagement", transform: (value) => value === "ACTIVE" },
                    "Enable": { target: "identityManagement", transform: (value) => value === "True" },
                    "TaskEnabled": { target: "TaskEnabled", transform: (value) => value === "True" },
                    "BRMTaskEnabled": { target: "BRMTaskEnabled", transform: (value) => value === "True" },
                    "CustomerSearch": { target: "CustomerSearch", transform: (value) => value === "True" },
                    "CustomerListing": { target: "CustomerListing", transform: (value) => value === "True" },
                    "OrderEnabled": { target: "orderManagement", transform: (value) => value === "True" },
                    "NotificationEnable": { target: "NotificationEnable", transform: (value) => value === "True" },
                    "SetPassword": { target: "SetPassword", transform: (value) => value === "True" },
                    "ProfileUpdate": { target: "ProfileUpdate", transform: (value) => value === "True" },
                    "EmailUpdate": { target: "EmailUpdate", transform: (value) => value === "True" },
                    "BalanceNotification": { target: "BalanceNotification", transform: (value) => value === "True" },
                    "BalanceAVNotification": { target: "BalanceAVNotification", transform: (value) => value === "True" },
                    "CreateCustomer": { target: "CreateCustomer", transform: (value) => value === "True" },
                    "CustomerUpdate": { target: "CustomerUpdate", transform: (value) => value === "True" },
                    "AddOnPurchase": { target: "AddOnPurchase", transform: (value) => value === "True" },
                    "PurchaseOffer": { target: "PurchaseOffer", transform: (value) => value === "True" },
                    "UnsubscribeEmail": { target: "UnsubscribeEmail", transform: (value) => value === "True" },
                    "ChangePlan": { target: "ChangePlan", transform: (value) => value === "True" },
                    "PaymentOrder": { target: "PaymentOrder", transform: (value) => value === "True" },
                    "PaymentConfirmation": { target: "PaymentConfirmation", transform: (value) => value === "True" },
                    "CancelProductOrder": { target: "CancelProductOrder", transform: (value) => value === "True" },
                    "SubscriptionFailed": { target: "SubscriptionFailed", transform: (value) => value === "True" },
                    "SubscriptionConfirmed": { target: "SubscriptionConfirmed", transform: (value) => value === "True" },
                    "CommissionStatement": { target: "CommissionStatement", transform: (value) => value === "True" },
                    "BillingEnabled": {target:"BillingManagement", transform: (value) => value === "True" },
                    "Trigger One Bill API": {target:"Trigger One Bill API", transform:(value)=> value === "true"},
                    "Generate CSV File": {target:"Generate CSV File", transform:(value)=> value === "true"},
                    "LLMEnabled": {target:"LLM", transform:(value)=> value === "True"},
                    "Mode":{target:"Mode", transform: (value)=> value},
                    "Limit": { target: "Limit", transform: (value) => value},
                    "Enabled": { target: "SessionAudit", transform: (value) => value === "True" },
                    "ExpiryInSeconds": { target: "ExpiryInSeconds", transform: (value) => value},
                    [camelizedName]: { target: camelizedName, transform: (value) => value === "True" }
                    
                };

                const selectStateMapping = {
                    "PaymentProvider": { target: "PaymentProvider", transform: (value) => ({ label: value, value: value }) },
                    "IdentityProvider": { target: "IdentityProvider", transform: (value) => ({ label: value, value: value }) }
                };

                if (switchStateMapping[val.key]) {
                    initialSwitchState[switchStateMapping[val.key].target] = switchStateMapping[val.key].transform(val?.value[0]);
                }

                if (selectStateMapping[val.key]) {
                    initialSelectState[selectStateMapping[val.key].target] = selectStateMapping[val.key].transform(val?.value[0]);
                }

            })
            initialSwitchState["customerRetrieval"] = (initialSwitchState["CustomerSearch"] || initialSwitchState["CustomerListing"])
            initialSwitchState["ratedEventConsumerConfiguration"] = (initialSwitchState["Trigger One Bill API"] || initialSwitchState["Generate CSV File"])
            initialSwitchState["UsageEventsPersistanceMode"] = (initialSwitchState["Mode"] && initialSwitchState["Mode"] !== "None")
            initialSwitchState["sessionAuditBuffer"] = (initialSwitchState["Limit"])
            initialSwitchState["reservationUnitsExpiration"] = (initialSwitchState["ExpiryInSeconds"])
            initialSelectState["PaymentProvider"] = initialSelectState["PaymentProvider"] || { label: "", value: "" }
            initialSelectState["IdentityProvider"] = initialSelectState["IdentityProvider"] || { label: "", value: "" }
        }
    });

    setSwitchState(initialSwitchState);
    setSelectState(initialSelectState);
}

export const handleSwitchToggle = (configName, setUseEffectEnabled, setSwitchState) => {
    setUseEffectEnabled(true);
    setSwitchState((prevState) => {
        const newState = { ...prevState, [configName]: !prevState[configName] };

        if (configName === "CustomerSearch" && newState[configName]) {
            newState["CustomerListing"] = false;
        } else if (configName === "CustomerListing" && newState[configName]) {
            newState["CustomerSearch"] = false;
        } else if (configName === "CustomerSearch" && !newState[configName]) {
            newState["CustomerListing"] = true;
        } else if (configName === "CustomerListing" && !newState[configName]) {
            newState["CustomerSearch"] = true;
        }

        return newState;
    });
};

export const handleSelectChange = (key, selectedOption, setSelectState) => {
    setSelectState(prevState => ({
        ...prevState,
        [key]: {label:selectedOption, value:selectedOption}
    }));
};

export const brmCheck = (switchState, setSwitchState) => {
    if (!switchState?.TaskEnabled) {
        setSwitchState(prevState => ({
            ...prevState,
            BRMTaskEnabled: false
        }));
    }
}

export const limitCheck = (useEffectEnabled, isCreate, setSwitchState) => {
    if (!useEffectEnabled && isCreate) {
        setSwitchState((prevState) => ({
            ...prevState,
            Limit: "10",
            ExpiryInSeconds: "3600", 
        }));
    }
}

export const customerRetrievalCheck = (useEffectEnabled, isCreate, setSwitchState, values) => {
    if (!useEffectEnabled && isCreate) {
        setSwitchState((prevState) => ({
            ...prevState,
            CustomerSearch: values?.Domain?.label === "Telco",
            CustomerListing: values?.Domain?.label !== "Telco",
        }));
    }
}

export const handleMenuChange = (config, setSelectedMenu, OperatorDefaultConfigurationList, setConfig)=>{
    if(config !== 'viewAll'){
        setSelectedMenu(config)
        const configurations=[];
        OperatorDefaultConfigurationList.map((val)=>{
            if(val.category === config)
                configurations.push(val)
        })
        setConfig(configurations);
    }else{
        setSelectedMenu('viewAll')
        setConfig(combinedConfigurations(OperatorDefaultConfigurationList))
    }
}

export const categoriesList = (OperatorDefaultConfigurationList) => {
    const categories=[];
    OperatorDefaultConfigurationList.map((val)=>{
        if(!categories.includes(val.category))
            categories.push(val.category)
    })
    return categories;
}
