export const countryOptionss = (countryData) => {
    const uniqueCountries = [...new Set(countryData || [])]; 
    const eventOptions = uniqueCountries.map((item) => ({
        label: item,
        value: item,
    }));

    return eventOptions;
};


export const OperatorOptionsFields = [
    { value: 'telcoAdmin', label: 'Telco' },
    { value: "operatorAdmin", label: 'EV' },
    { value: "genericAdmin", label: 'Generic' },
    { value: "ISPOperatorAdmin", label: 'ISP' }
]

export const OperatorStatusOptions = [
    { value: 'validated', label: 'Active' },
    { value: 'initialized', label: 'Inactive' },
    { value: 'closed', label: 'Cancel' }
]


export const configDisplayNameMapping = {
    ExpiryInSeconds:"Reservation Units Expiration",
    UsageEventsPersistanceMode:"Usage Persistance Mode",
    BillingManagement: "Billing Management",
    SessionAudit:"Session Audit",
    SessionAuditBuffer: "Session Audit Buffer",
    Limit:"Limit",
    paymentManagement: "Payment",
    TaskEnabled: "Tasks",
    BRMTaskEnabled:"BRM Task Integration",
    whatsAppManagement: "WhatsApp",
    notificationManagement: "Notifications",
    identityManagement: "IDM",
    policySpecifications: "Policy Specifications",
    customerRetrieval: "Customer Retrieval",
    selfcare: "Selfcare",
    orderManagement: "Order Management",
    LLM: "LLM"
};