import { Card, CardBody, Row, Col, Input, CardHeader } from "reactstrap";
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { LabelText } from "../Utils/Input";
import { Loader } from "../Utils/Loader";
import { useEffect, useState } from "react";
import { fetchAllOperatorDefaultConfigurations } from "../../slices/OperatorManagement/GetAllDefaultConfiguraions/thunk";
import { useDispatch, useSelector } from "react-redux";
import { brmCheck, categoriesList, combinedConfigurations, customerRetrievalCheck, handleMenuChange, handleSelectChange, handleSwitchToggle, limitCheck, mapConfig } from "./Constant/ConfigFunction";
import { configDisplayNameMapping } from "./Constant/Options";
import { Slider } from "./Slider";

export default function ConfigurationForOperator({ setFinalValues, finalValues, values, configData, isEdit}) {
    const [loading, setLoading] = useState(false);
    const [switchState, setSwitchState] = useState({});
    const [selectState, setSelectState] = useState({});
    const [useEffectEnabled, setUseEffectEnabled] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [config, setConfig] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [offcanvasConfig, setOffcanvasConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const isCreate = location.pathname.includes('create-operator')

    useEffect(() => {
        if (values?.Domain) {
            dispatch(fetchAllOperatorDefaultConfigurations(setLoading, values.Domain.label));
        }
    }, [dispatch, values?.Domain]);

    const OperatorDefaultConfigurationList = useSelector((state) => state.GetAllDefaultConfiguration.OperatorDefaultConfiguration);

    useEffect(() => {
        mapConfig(isEdit, configData, OperatorDefaultConfigurationList, setSwitchState, setSelectState)
    }, [OperatorDefaultConfigurationList, isEdit]);

    useEffect(() => {
        if (OperatorDefaultConfigurationList) {
            setSelectedMenu('viewAll');
            setConfig(combinedConfigurations(OperatorDefaultConfigurationList));
            setCategories(categoriesList(OperatorDefaultConfigurationList));
        }
    }, [OperatorDefaultConfigurationList]);

    useEffect(() => {
        brmCheck(switchState, setSwitchState)
    }, [switchState?.TaskEnabled])

    useEffect(() => {
        customerRetrievalCheck(useEffectEnabled, isCreate, setSwitchState, values)
    }, [isCreate, values?.Domain,switchState?.CustomerSearch,switchState?.CustomerListing]);
    
    useEffect(()=>{
        limitCheck(useEffectEnabled,isCreate,setSwitchState);
    },[isCreate, switchState?.Limit,switchState?.ExpiryInSeconds])

    useEffect(() => {
        const combinedValues = {
            ...switchState,
            ...Object.fromEntries(
                Object.entries(selectState)?.map(([key, value]) => [key, value?.value])
            )
        };
        setFinalValues(combinedValues);
    }, [switchState, selectState]);

    useEffect(() => {
        if(searchTerm){
            setFilteredCategories(categories?.filter(category=>(
                category?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            )))
        }else{
            setFilteredCategories(categoriesList(OperatorDefaultConfigurationList));
        }
    }, [searchTerm,OperatorDefaultConfigurationList])

    const toggleOffcanvas = (conf) => {
        setOffcanvasConfig(conf);
        setIsOpen(true);
    };

    const closeOffcanvas = () => {
        setIsOpen(false);
        setOffcanvasConfig(null);
    };

    const handleInputChange = (configName, newValue) => {
        setUseEffectEnabled(true)
        const updatedValue = newValue || (configName === "Limit" ? "10" : "3600");
        setSwitchState((prevState) => ({
          ...prevState,
          [configName]: updatedValue,
        }));
    };
    
    const handleRadioChange = (key, value) => {
        setSwitchState((prevState) => {
            const newState = { ...prevState, [key]: value };
            if (key === "CustomerSearch" && newState[key]) {
                newState["CustomerListing"] = false;
            } else if (key === "CustomerListing" && newState[key]) {
                newState["CustomerSearch"] = false;
            } else if (key === "CustomerSearch" && !newState[key]) {
                newState["CustomerListing"] = true;
            } else if (key === "CustomerListing" && !newState[key]) {
                newState["CustomerSearch"] = true;
            }
            return newState;
        });
    };

    const handleSave = () => {
        setIsOpen(false);
        setOffcanvasConfig(null);
    };      


    const handleSelectAllChange = () => {
        setSwitchState((prevState) => {
            const selectAllState = !prevState["Select All"]; 
            const updatedState = { ...prevState, ["Select All"]: selectAllState };
            const notificationManagement = OperatorDefaultConfigurationList?.find(
                (config) => config.name === "notificationManagement"
            );
            if (notificationManagement) {
                notificationManagement?.value.forEach((config) => {
                    updatedState[config.key] = selectAllState; 
                });
            }
            return updatedState;
        });
    };
    
    

    const handleCheckboxChange = (item) => {
        setSwitchState((prevState) => ({
            ...prevState,
            [item]: !prevState[item]
        }));
    };

    return (
        <div>
            <Card className="card-config">
                <CardHeader className="px-3 pt-3 fs-4">Configurations</CardHeader>
                {loading && <Loader />}
                <CardBody>
                    <Row className="gy-4">
                        <Col lg={2} md={3} className="border-end mt-2">
                        <div className="navbar-conf">
                            <div className='search-box mt-2' >
                                <span className='ri-search-line search-icon'></span>
                                <input
                                    type='text'
                                    className='form-control bg-light'
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <Navbar className="nav-conf">
                                <Nav navbar>
                                    {/* {filteredCategories.length === categories.length &&  */}
                                    <NavItem>
                                        <NavLink 
                                            className={`nav-items ${selectedMenu === 'viewAll' ? 'active' : ''}`} 
                                            onClick={() => handleMenuChange('viewAll', setSelectedMenu, OperatorDefaultConfigurationList, setConfig)}
                                        >
                                            View All
                                        </NavLink>
                                    </NavItem>
                                    {/* }    */}
                                    {filteredCategories?.map((category, index) => (
                                        <NavItem key={index}>
                                            <NavLink 
                                                className={`nav-items ${selectedMenu === category ? 'active' : ''}`} 
                                                onClick={() => handleMenuChange(category, setSelectedMenu, OperatorDefaultConfigurationList, setConfig)}
                                            >
                                                {category}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </Navbar>
                            </div>
                        </Col>
                        
                        <Col lg={10} md={9}>
                        <div className="cardbody-conf">
                            <Row>
                            {config?.map((configuration, index) => (
                                (configuration.name !== "identityManagement" ) &&
                                ((configuration.name==="paymentManagement" || configuration.name === "identityManagement" || configuration.name === "UsageEventsPersistanceMode" || configuration.name === "customerRetrieval" || configuration.name === "notificationManagement" || (configuration.name === "Rated Event Consumer Configuration" && switchState["Mode"] && switchState["Mode"] !== "None")) ? (
                                    <Col lg={3} md={6} key={`${index}`} className="my-2">
                                    <Card className="border card-conf">
                                        <CardBody >
                                            <div className="d-flex align-items-center mb-4">
                                                <div>
                                                {configuration.name === "paymentManagement" && <i className="ri-bank-card-line icon-conf me-3" />}
                                                {(configuration.name === "customerRetrieval" || configuration.name === "identityManagement") && <i class="ri-user-line icon-conf me-3"></i>}
                                                {configuration.name === "UsageEventsPersistanceMode" && <i className="ri-pie-chart-2-line icon-conf me-3" />}
                                                {configuration.name === "notificationManagement" && <i className="ri-notification-line icon-conf me-3" />}
                                                {configuration.name === "Rated Event Consumer Configuration" && <i className="ri-exchange-funds-line icon-conf me-3" />}
                                                </div>
                                            </div>
                                            <div className="form-switch d-flex ms-0 ps-0 justify-content-between">
                                                <LabelText 
                                                    displayText={configDisplayNameMapping[configuration.name] || configuration.name} 
                                                    className="me-5 mb-1" 
                                                />
                                                <Input
                                                    type="switch"
                                                    checked={(configuration.name === "paymentManagement" || configuration.name === "identityManagement") ? (selectState[configuration.value[0].key]?.value && selectState[configuration.value[0].key]?.value!==""):(configuration.name === "UsageEventsPersistanceMode")?(switchState["Mode"] && switchState["Mode"] !== "None"):(configuration.name === "customerRetrieval" || configuration.name === "Rated Event Consumer Configuration")?(switchState[configuration.value[0].key] || switchState[configuration.value[1].key]):switchState[configuration.name]}                                                    
                                                    onChange={() => toggleOffcanvas(configuration) }
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                ): 
                                configuration?.value?.map((conf, confIndex) => {
                                    if(conf.key !== "PaymentEnabled" && conf.key !=="Generate CSV File" && conf.key !== "Trigger One Bill API"){
                                    const isBoolean = conf.value[0] === "True";
                                    const isArray = conf.value.length > 1;
                                    const isSingle = conf.value.length === 1;
                                    return (
                                        <Col lg={3} md={6} key={`${index}-${confIndex}`} className="my-2">
                                            <Card className="border card-conf">
                                                <CardBody >
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div>
                                                        {configuration.name === "taskManagement" && <i class="ri-whatsapp-line icon-conf me-3"/> }
                                                        {configuration.name === "whatsAppManagement" && <i class="ri-whatsapp-line icon-conf me-3"/> }
                                                        {configuration.name === "policySpecifications" && <i class="ri-article-line icon-conf me-3"/>}
                                                        {configuration.name === "selfcare" && <i className="ri-dashboard-line icon-conf me-3" />}
                                                        {(configuration.name === "SessionAudit" || configuration.name === "SessionAuditBuffer") && <i className="ri-timer-2-line icon-conf me-3" />}
                                                        {configuration.name === "BillingManagement" && <i className="ri-bill-line icon-conf me-3" />}
                                                        {configuration.name === "orderManagement" && <i className="ri-shopping-cart-line icon-conf me-3" />}
                                                        {configuration.name === "ReservationUnitsExpiration" && <i className="ri-refresh-line icon-conf me-3" />}
                                                        {configuration.name === "LLM" && <i className="mdi mdi-star-four-points-outline icon-conf me-3" />}
                                                        </div>
                                                    </div>
                                                    <div className="form-switch d-flex ms-0 ps-0 justify-content-between">
                                                        <LabelText 
                                                            displayText={configDisplayNameMapping[configuration.name] || configDisplayNameMapping[conf.key] || conf.key} 
                                                            className="me-5" 
                                                        />
                                                        {isSingle ? (
                                                            <Input
                                                                type="switch"
                                                                checked={switchState[conf.key] || false}
                                                                onChange={() => toggleOffcanvas(configuration)}
                                                            />
                                                        ) : (
                                                            <Input
                                                                type="switch"
                                                                checked={configuration.name === "taskManagement" ? switchState[conf.key]:switchState[configuration.name] || false}
                                                                disabled={configuration.name === "taskManagement" ? (!switchState["TaskEnabled"] && confIndex !== 0) : false}
                                                                onChange={() => {
                                                                    if (isBoolean) {
                                                                        if(configuration.name === "taskManagement"){ 
                                                                            handleSwitchToggle(conf.key, setUseEffectEnabled, setSwitchState)
                                                                        } else {
                                                                            handleSwitchToggle(configuration.name, setUseEffectEnabled,setSwitchState)
                                                                        }
                                                                    } else if (isArray) {
                                                                        toggleOffcanvas(conf);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                    }}
                                ))
                                ))}
                            </Row>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {offcanvasConfig && (
                    <Slider
                        isOpen={isOpen}
                        setOpen={closeOffcanvas}
                        actionName={
                            configDisplayNameMapping[offcanvasConfig?.name] || 
                            configDisplayNameMapping[offcanvasConfig?.value[0]?.key] ||
                            offcanvasConfig?.name
                        }
                        handleSave={handleSave}
                    >
                        <Row>
                            <Col lg={6} md={8}>
                                {offcanvasConfig?.name === "paymentManagement" || offcanvasConfig?.name === "identityManagement" ? (
                                    offcanvasConfig?.value?.map((item, idx) => (
                                        (item.key === "PaymentProvider" || item.key === "IdentityProvider") && (
                                            <div key={idx} className="mb-2">
                                                <LabelText displayText={item.key === "PaymentProvider" ? "Payment Provider" : "Identity Provider"} />
                                                {item?.value?.map((provider, providerIdx) => (
                                                    <div key={providerIdx} className="border rounded-4 p-2 mb-2">
                                                        <Input
                                                            type="radio"
                                                            checked={selectState[item.key]?.value === provider}
                                                            className="me-2"
                                                            onChange={() => handleSelectChange(item.key, provider, setSelectState)}
                                                        />
                                                        <LabelText displayText={provider} />
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ))
                                ) : offcanvasConfig?.name === "UsageEventsPersistanceMode" ? (
                                    offcanvasConfig?.value?.map((item, idx) => (
                                        item.key === "Mode" && (
                                            <div key={idx} className="mb-2">
                                                <LabelText displayText="Mode" />
                                                {item.value.map((mode, modeIdx) => (
                                                    <div key={modeIdx}  className="border rounded-4 p-2 mb-2">
                                                        <Input
                                                            type="radio"
                                                            name="mode"
                                                            className="me-2"
                                                            checked={switchState["Mode"] === mode}
                                                            onChange={() => handleRadioChange("Mode", mode)}
                                                        />{" "}
                                                        {mode}
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ))
                                ) : offcanvasConfig?.name === "customerRetrieval" ? (
                                    <div>
                                        <LabelText displayText="Customer Retrieval" />
                                        {offcanvasConfig?.value?.map((item, ind) => (
                                            <div key={ind} className="border rounded-4 p-2 mb-2">
                                                <Input
                                                    type="radio"
                                                    name="customerRetrieval"
                                                    className="me-2"
                                                    checked={switchState[item.key]}
                                                    onChange={() => handleSwitchToggle(item.key, setUseEffectEnabled, setSwitchState)}
                                                />
                                                <LabelText displayText={item.key} />
                                            </div>
                                        ))}
                                    </div>
                                ) : offcanvasConfig?.value.length === 1 ? (
                                    <div>
                                        <LabelText
                                            displayText={
                                                configDisplayNameMapping[offcanvasConfig?.value[0]?.key] || 
                                                configDisplayNameMapping[offcanvasConfig.name]
                                            }
                                            className="me-5"
                                        />
                                        <Input
                                            type="number"
                                            value={
                                                offcanvasConfig?.name === "SessionAuditBuffer" 
                                                    ? switchState["Limit"] 
                                                    : switchState["ExpiryInSeconds"]
                                            }
                                            onChange={(e) => handleInputChange(offcanvasConfig?.value[0]?.key,e.target.value)}
                                        />
                                    </div>
                                ) : (
                                    <>
                                    {offcanvasConfig?.name === "notificationManagement" && 
                                    (
                                    <div>
                                        <Input
                                        type="checkbox"
                                        className="me-2"
                                        checked={switchState["Select All"] || false}
                                        onChange={() => handleSelectAllChange()}
                                        />
                                        <LabelText displayText="Select All"/> </div>
                                    )}
                                    {offcanvasConfig?.value?.map((item, idx) => (
                                        <div key={idx} className="border rounded-4 p-2 mb-2">
                                            <Input
                                                type="checkbox"
                                                className="me-2"
                                                checked={switchState[item.key] || false}
                                                onChange={() => handleCheckboxChange(item.key)}
                                            />
                                            <LabelText displayText={item.key} />
                                        </div>
                                    ))}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Slider>
                )}
            </Card>
        </div>
    );
}